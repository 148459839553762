<template>
  <div class="shop-messages">
    <chat />
  </div>
</template>

<script>
import Chat from '@/views/shared/chat-full-screen/Chat.vue'

export default {
  components: {
    Chat,
  },
}
</script>
